import React from "react"
import { graphql } from "gatsby"

const CategoryTemplate = ({ data, pageContext }) => {

  const catName = pageContext.catName
  const catDesc = pageContext.catDescription

  return (

    <>

      <h1 dangerouslySetInnerHTML={{ __html: catName }} />

      {catDesc}

    </>

  )

}

export default CategoryTemplate;

export const pageQuery = graphql`
  query($id: String!) {
    allWpPost(
      filter: { categories: { nodes: { elemMatch: { id: { eq: $id} } } } }
    ) {
      nodes {
          ...postFragments
      }
    }
  }
`;
